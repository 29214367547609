<template>
  <div>
    <div class="myposition"></div>
    <div class="main">
      <mt-header title="修改资料" style="height:0.88rem;font-size: 0.35rem;">
        <!-- <router-link to="/my/set_up/set_up" slot="left"> -->
        <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
        <!--  </router-link> -->
      </mt-header>
      <div class="main_text">
        <mt-field class="main_text_mt-field" label="用户名" placeholder="请输入用户名" v-model="username"></mt-field>
        <mt-field class="main_text_mt-field" label="昵称" placeholder="不要超过十个字" v-model="username"></mt-field>
        <mt-field
          class="main_text_mt-field"
          label="电子邮箱"
          placeholder="选填，仅作为活动联系方式"
          type="email"
          v-model="username"
        ></mt-field>
        <mt-field class="main_text_mt-field" label="QQ" placeholder="选填，请输入QQ号码" v-model="username"></mt-field>
      </div>
      <mt-button class="main_button" type="primary" @click="getout()">确认修改</mt-button>
      <span>温馨提示：</span>
      <span>1.如有问题请联系客服人员</span>
    </div>
  </div>
</template>

<style scoped>
.myposition {
  background-color: rgba(239, 239, 239, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.main_text {
  float: left;
  width: 100%;
  background-color: white;
  margin-top: 0.3rem;
}
.main_text_mt-field {
  float: left;
  border-bottom: 1px solid rgba(187, 187, 187, 1);
  width: 100%;
}
.main_button {
  width: 7.12rem;
  height: 0.88rem;
  margin-left: 0.2rem;
}
span {
  float: left;
  width: 90%;
  height: 0.2rem;
  font-size: 0.2rem;
  color: rgba(153, 153, 153, 1);
  margin: 0.2rem 0 0 0.2rem;
}
</style>

<script>
export default {
  data() {
    return {
      username: ""
    };
  },
  methods: {
    left_click() {
      let hisNum = parseInt(window.history.length);
      if (hisNum == 1) {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
      // this.$router.push({path:'/t?type='+JSON.parse(localStorage.getItem('game')).type+'&room='+JSON.parse(localStorage.getItem('game')).room})
    },
    getout() {
      this.$toast("请输入正确的电子邮件");
    }
  }
};
</script>
